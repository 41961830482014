import { Avatar } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Bubble from "./Bubble";

function BubbleRight() {
  return (
    <Container>
      <Bubble color="#4285F4" text="Message" />
      <Avatar style={{ marginLeft: 10 }} sizes="large" />
    </Container>
  );
}

export default BubbleRight;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  justify-content: flex-end;
`;
