import React, { useRef } from "react";
import styled from "styled-components";
import Button from "../../components/Button/Button";

import { FONTS } from "../../constants/fonts";

interface MessageInputProps {
  onSend: (message: string) => void;
}

function MessageInput(props: MessageInputProps) {
  const messageRef = useRef<HTMLInputElement>(null);

  const handleSend = () => {
    props.onSend(messageRef.current?.value!);
    // Clear input field
    if (messageRef.current) {
      messageRef.current.value = "";
    }
  };

  return (
    <Container>
      <InputContainer>
        <Input ref={messageRef} />
      </InputContainer>

      <Button
        width={100}
        height={50}
        color={"black"}
        text={"Send"}
        onClick={handleSend}
      />
    </Container>
  );
}

export default MessageInput;

const Container = styled.div`
  display: flex;
`;
const Input = styled.input`
  flex: 1;
  font-family: ${FONTS.MONTSERRAT};
  font-weight: 500;
  text-align: right;
  outline: none;
  border: none;
  background-color: lightgrey;
`;

const InputContainer = styled.div`
  height: 50px;
  flex: 1;
  background-color: lightgrey;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  margin-right: 30px;
`;
