import styled from "styled-components";
import SearchBar from "../../components/Search/SearchBar";
import SearchItem from "../../components/Search/SearchItem";
import { FONTS } from "../../constants/fonts";
import MentorList from "../../components/List/MentorList";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { createMentiUser } from "../../graphql/mutations";
import {
  getMentiUser,
  listMentiUsers,
  listMentorUsers,
} from "../../graphql/queries";
import { Gender, MentorUser } from "../../models";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  CreateMentiUserInput,
  GetMentiUserQuery,
  ListMentiUsersQuery,
  ListMentorUsersQuery,
} from "../../graphql/GraphQLApi";

function Mentors() {
  const [mentors, setMentors] = useState<any>([]);
  const myInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      // id: "param", // OPTIONAL
    },
  };

  const search = async () => {
    const mentors = await API.graphql<GraphQLQuery<ListMentorUsersQuery>>({
      query: listMentorUsers,
      // variables: { id: "some id" },
    });
    setMentors(mentors.data?.listMentorUsers?.items);
    console.log("New Menti User: ", mentors.data?.listMentorUsers?.items);
  };

  useEffect(() => {
    search();
  }, []);

  return (
    <Container>
      <Title>Mentors</Title>
      <SearchBar />
      <MentorList mentors={mentors} />
    </Container>
  );
}

export default Mentors;

const Container = styled.div`src\graphql
  padding: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fbf7f4;
`;

const Title = styled.p`
  font-family: ${FONTS.VIGA};
  font-size: 40px;
  text-align: left;
`;

const SearchResultContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: auto;
  flex-direction: column;
`;
