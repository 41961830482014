import { Avatar } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Bubble from "./Bubble";

function BubbleRight() {
  return (
    <Container>
      <Avatar style={{ marginRight: 10 }} sizes="large" />
      <Bubble color="#D16666" text="Message" />
    </Container>
  );
}

export default BubbleRight;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  justify-content: flex-start;
`;
