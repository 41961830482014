import { MentiUser, Action } from "../../types/types";
import { USER_SIGNIN, USER_LOGOUT, USER_SIGNUP } from "../types";

interface AuthState {
  isSignedIn: boolean;
}

const defaultState: AuthState = {
  isSignedIn: false,
};

const authReducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case USER_SIGNUP:
      return { ...state, ...action.payload.auth };
    case USER_SIGNIN:
      return { ...state, ...action.payload };
    case USER_LOGOUT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default authReducer;
