import { Avatar } from "@mui/material";
import styled from "styled-components";
import { FONTS } from "../../constants/fonts";

function MessageCard() {
  return (
    <Container>
      <AvatarContainer>
        <Avatar
          sx={{ width: 40, height: 40 }}
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "#D16666",
          }}
        />
      </AvatarContainer>
      <DetailsContainer>
        <Name>Jennifer Hudson</Name>
        <Text>Incididunt duis exercitation commodo nostrud nulla ...</Text>
      </DetailsContainer>
    </Container>
  );
}

export default MessageCard;

const Container = styled.button`
  display: flex;
  height: 80px;
  width: 100%;
  background: none;
  border-bottom: lightgrey solid 1px;
  flex-direction: row;
  padding-top: 5px;
  color: inherit;
  border: none;
`;

const AvatarContainer = styled.div`
  display: flex;
  width: 40px;
  height: 100%;
  justify-content: center;
  padding-top: 8px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 10px;
`;

const Name = styled.span`
  font-family: ${FONTS.MONTSERRAT};
  font-weight: 500;
  font-size: 13px;
  text-align: left;
`;

const Text = styled.p`
  color: lightgrey;
  text-align: left;
  line-height: 16px;
  margin-top: 5px;
`;
