import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Mentor } from "../../types/types";
import MentorPreviewCard from "../Card/MentorPreviewCard";
import { Separator } from "../components";

interface AvailableMentorProps {
  mentors: Mentor[];
}

function AvailableMentors(props: AvailableMentorProps) {
  return (
    <Body>
      <Container>
        <Row>
          <Col>
            {props.mentors.map((mentor) => (
              <>
                <MentorPreviewCard
                  key={mentor.id}
                  name={mentor.firstname + " " + mentor.lastname}
                  avi={mentor.avi}
                  position={mentor.position}
                  description={mentor.description}
                />
                <Separator size={30} />
              </>
            ))}
          </Col>
        </Row>
      </Container>
    </Body>
  );
}

export default AvailableMentors;

const Body = styled.div`
  margin-top: 30px;
  min-height: 100px;
  border-radius: 20px;
`;
