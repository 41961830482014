import { combineReducers } from "redux";

import UserReducer from "./UserReducer";
import AuthReducer from "./AuthReducer";

const RootReducer = combineReducers({
  UserReducer,
  AuthReducer,
});

export default RootReducer;
