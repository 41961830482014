import React, { useState } from "react";
import styled from "styled-components";
import Availability from "../components/Availability/Availability";
import AvailableMentors from "../components/Availability/AvailableMentors";
import { Wrapper } from "../components/components";
import { Mentor } from "../types/types";

function Schedule() {
  const [list, setList] = useState<Mentor[]>(mentors);

  const handleChange = (time: [number, number]) => {
    console.log(time);
    setList(
      mentors.filter(
        (mentor) =>
          mentor.availability!.monday.from >= time[0] &&
          mentor.availability!.monday.to <= time[1]
      )
    );
  };

  return (
    <Wrapper>
      <Body>
        <Title>Schedule</Title>
        <Availability handleChange={handleChange} />
        <AvailableMentors mentors={list} />
      </Body>
    </Wrapper>
  );
}

export default Schedule;

const Title = styled.h1`
  font-family: Viga;
  font-size: 40px;
  color: white;
`;

const Body = styled.div`
  flex: 1;
  padding: 30px;
`;

const mentors: Mentor[] = [
  {
    id: "123495867392",
    firstname: "John",
    lastname: "Doe",
    avi: "https://i.picsum.photos/id/687/200/200.jpg?hmac=U-mrTuk3Y5M3brBJ76mYvaj-bZ3ggY1OD8YOIPw89uI",
    position: "Civil Engineer",
    interest: "Mechanical Engineer",
    industry: "Construction",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    dob: "1/23/56",
    city: "Los Angeles",
    state: "California",
    createdAt: 1,
    availability: {
      monday: { from: 12, to: 14 },
      tuesday: { from: 6, to: 7 },
      wednesday: { from: 3, to: 4 },
      thursday: { from: 5, to: 7 },
      friday: { from: 8, to: 10 },
    },
  },
  {
    id: "1234952kmsa92",
    firstname: "Robert",
    lastname: "Downing",
    avi: "https://i.picsum.photos/id/830/200/200.jpg?hmac=3ce7zNUn5yg_XKy7dHgIHta7t_0vghPQnAGUSGJuBZE",
    position: "Civil Engineer",
    interest: "Mechanical Engineer",
    industry: "Construction",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    city: "Los Angeles",
    state: "California",
    dob: "1/2/12",
    createdAt: 1,
    availability: {
      monday: { from: 7, to: 9 },
      tuesday: { from: 6, to: 7 },
      wednesday: { from: 3, to: 4 },
      thursday: { from: 5, to: 7 },
      friday: { from: 8, to: 10 },
    },
  },
  {
    id: "1kmklMKL95867392",
    firstname: "James",
    lastname: "Larry",
    avi: "https://i.picsum.photos/id/872/200/200.jpg?hmac=m0AwAUFkEiEz2KW58n6a5RVkKaClHNylfppYjE3a0v4",
    position: "Civil Engineer",
    interest: "Mechanical Engineer",
    industry: "Construction",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    city: "Los Angeles",
    state: "California",
    dob: "12/21/2001",
    createdAt: 1,
    availability: {
      monday: { from: 2, to: 4 },
      tuesday: { from: 6, to: 7 },
      wednesday: { from: 3, to: 4 },
      thursday: { from: 5, to: 7 },
      friday: { from: 8, to: 10 },
    },
  },
];
