import React, { useState } from "react";
import styled from "styled-components";
import { FONTS } from "../constants/fonts";
import MessageAbout from "./Messages/About";
import MessaagesList from "./Messages/MessagesList";
import MessageWindow from "./Messages/MessageWindow";
import Chat from "./Messages/Chat";
import MessageInput from "./Messages/MessageInput";
import { Inbox } from "../models";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { CreateInboxInput, CreateMessageInput } from "../graphql/GraphQLApi";
import { createInbox } from "../graphql/mutations";
import { useAppSelector } from "../redux/hooks";

function Messages() {
  const [inbox, setInbox] = useState<Inbox>();
  const user = useAppSelector((state) => state.UserReducer);
  const onSelectInbox = () => {};
  const onSendMessage = (message: string) => {
    const newInbox: CreateInboxInput = {
      mentor: inbox?.mentor,
      mentee: inbox?.mentee,
      lastMessage: message,
    };
    const newMessage: CreateMessageInput = {
      message: message,
      avatar: user.avatar,
      inbox: inbox?.id,
    };
    API.graphql<GraphQLQuery<CreateInboxInput>>({
      query: createInbox,
      variables: { input: newInbox },
    });

    API.graphql<GraphQLQuery<CreateMessageInput>>({
      query: createInbox,
      variables: { input: newMessage },
    });
  };
  return (
    <div className="flex flex-1 flex-col">
      <Title>Messages</Title>
      <div className="flex flex-1 flex-row">
        <MessaagesList />
        <div className="flex flex-1 flex-col px-5 border-l ">
          <Chat />
          <MessageInput onSend={onSendMessage} />
        </div>
      </div>
    </div>
  );
}

export default Messages;

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 40px;
`;

const Title = styled.p`
  font-family: ${FONTS.VIGA};
  font-size: 40px;
  text-align: left;
`;
