import React from "react";
import styled from "styled-components";
import BubbleLeft from "../../components/Chat/BubbleLeft";
import BubbleRight from "../../components/Chat/BubbleRight";

function Chat() {
  return (
    <div className="flex flex-1 flex-col mb-6">
      <BubbleRight />
      <BubbleLeft />
      <BubbleRight />
    </div>
  );
}

export default Chat;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 30px;
`;
