import styled from "styled-components";
import { FONTS } from "../constants/fonts";
import CalendarView from "./Calendar/CalendarView";

function Calendar() {
  return (
    <Container>
      <Title>Calendar</Title>
      <div
        style={{
          display: "flex",
          flex: 1,
          position: "relative",
        }}
      >
        <CalendarView />
      </div>
    </Container>
  );
}

export default Calendar;

const Title = styled.p`
  font-family: ${FONTS.VIGA};
  font-size: 40px;
  text-align: left;
`;

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  position: relative;
`;
