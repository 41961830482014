import styled from "styled-components";
import VerifiedBadge from "../Badges/VerifiedBadge";

interface MentorHeaderProps {
  name: string;
  verified: boolean;
  company: string;
  position: string;
  avatar: string;
}

function MentorHeader(props: MentorHeaderProps) {
  return (
    <Container>
      <div className="md:flex md:items-center md:justify-between md:space-x-5">
        <div className="flex items-start space-x-5">
          <div className="flex-shrink-0">
            <div className="relative">
              <img
                className="h-28 w-28 rounded-full"
                src={props.avatar}
                alt={props.name}
              />
              <span
                className="absolute inset-0 rounded-full shadow-inner"
                aria-hidden="true"
              />
            </div>
          </div>
          {/*
          Use vertical padding to simulate center alignment when both lines of text are one line,
          but preserve the same layout if the text wraps without making the image jump around.
        */}
          <div className="pt-3.5">
            <h1 className="text-2xl font-bold text-gray-900">
              {props.name} <VerifiedBadge />
            </h1>
            <p className="text-sm font-medium text-gray-500">
              Currently employed at{" "}
              <button className="text-gray-900">{props.company}</button> as a{" "}
              <span className="text-gray-900">{props.position}</span>
            </p>
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          {/* <button
            type="button"
            className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Disqualify
          </button> */}
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Subscribe
          </button>
        </div>
      </div>
    </Container>
  );
}

export default MentorHeader;

const Container = styled.div`
  margin-top: 30px;
`;
