import React from "react";
import styled from "styled-components";
import { BLUE } from "../../constants/colors";

interface ButtonProps {
  width: number;
  height: number;
  color: string;
  text: string;
  onClick?: () => void;
  textColor?: string;
  fontSize?: number;
  shadow?: boolean;
}

function Button(props: ButtonProps) {
  return (
    <StyledButton
      width={props.width}
      height={props.height}
      buttonColor={props.color}
      textColor={props.textColor ? props.textColor : "white"}
      fontSize={props.fontSize}
      onClick={props.onClick}
    >
      {props.text}
    </StyledButton>
  );
}

export default Button;

interface StyledButtonProps {
  width: number | string;
  height: number | number;
  buttonColor: string;
  textColor: string;
  fontSize?: number;
  shadow?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.buttonColor};
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 10px;
  border: none;
  ${(props) => (props.shadow ? "box-shadow: 5px 5px 10px 10px grey;" : null)};
  font-family: Viga;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 16)}px;
  &:hover { background-color: ${BLUE};
    &:active {
    outline: none;
    background-color: #6BBFF2;
    }
`;
