/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMentorUser = /* GraphQL */ `
  query GetMentorUser($id: ID!) {
    getMentorUser(id: $id) {
      id
      name
      dob
      email
      avatar
      gender
      description
      company
      position
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMentorUsers = /* GraphQL */ `
  query ListMentorUsers(
    $filter: ModelMentorUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMentorUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        dob
        email
        avatar
        gender
        description
        company
        position
        rating
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      message
      avatar
      inbox
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        avatar
        inbox
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInbox = /* GraphQL */ `
  query GetInbox($id: ID!) {
    getInbox(id: $id) {
      id
      lastMessage
      mentor
      mentee
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInboxes = /* GraphQL */ `
  query ListInboxes(
    $filter: ModelInboxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInboxes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastMessage
        mentor
        mentee
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMentiUser = /* GraphQL */ `
  query GetMentiUser($id: ID!) {
    getMentiUser(id: $id) {
      id
      name
      dob
      email
      avatar
      description
      interest
      gender
      city
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMentiUsers = /* GraphQL */ `
  query ListMentiUsers(
    $filter: ModelMentiUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMentiUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        dob
        email
        avatar
        description
        interest
        gender
        city
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
