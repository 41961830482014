import { Wrapper } from "../components/components";
import VerificationCode from "../containers/VerificationCode";

function Verification() {
  return (
    <Wrapper>
      <VerificationCode />
    </Wrapper>
  );
}

export default Verification;
