import { ChevronLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FONTS } from "../../constants/fonts";

function BackButton() {
  const navigate = useNavigate();
  const onBack = () => navigate(-1);
  return (
    <Container onClick={onBack}>
      <ChevronLeft size={20} />
      <Text>Back</Text>
    </Container>
  );
}

export default BackButton;

const Container = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80px;
  background: none;
  color: inherit;
  border: none;
`;

const Text = styled.p`
  margin: 0;
  font-family: ${FONTS.MONTSERRAT};
  font-weight: 500;
  margin-left: 10px;
`;
