import styled from "styled-components";
import { BLUE, VINTAGE, WEDDING } from "../constants/colors";
import {
  ButtonProps,
  RoundImageProps,
  UploadProps,
  SeparatorProps,
} from "./componentTypes";

export const Wrapper = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  background-color: #fbf7f4;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

export const Input = styled.input`
  height: 40px;
  width: 80%;
  max-width: 300px;
  padding-left: 10px;
  border-radius: 10px;
  border: 2px solid ${WEDDING};
  font-family: Viga;
  &:focus,
  &:active {
    outline: none;
    border: ${VINTAGE} solid 2px;
  }
`;

export const Button = styled.button<ButtonProps>`
  width: ${(props) => (props.width ? props.width + "px" : "100%")};
  height: ${(props) => props.height}px;
  border-radius: 10px;
  border: none;
  font-family: Viga;
  font-size: 20px;
  background-color: ${(props) => props.color};
  color: white;
  &:hover { background-color: ${BLUE};
    &:active {
    outline: none;
    background-color: #6BBFF2;
    }
`;

export const RoundImage = styled.img<RoundImageProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size + 5 / 2}px;
  background-color: lightgrey;
  border: ${(props) =>
    props.border ? `${props.borderColor} solid ${props.borderSize}px` : `none`};
  object-fit: cover;
  padding: 0;
`;

export const Upload = styled.input<UploadProps>`
  font-family: Viga;
  font-size: 20px;
  background-color: ${VINTAGE};
`;

export const LabelText = styled.span`
  font-family: Viga;
  font-size: 16px;
  color: ${VINTAGE};
`;

export const Center = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Separator = styled.div<SeparatorProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;
