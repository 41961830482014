import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import styled from "styled-components";
import { Button, Center } from "../components";

interface MentorPreviewCardProps {
  name: string;
  avi: string;
  position: string;
  description: string;
}

function MentorPreviewCard(props: MentorPreviewCardProps) {
  return (
    <Body>
      <Center>
        <Container fluid>
          <Row>
            <Col xs={3}>
              <Image
                src={props.avi}
                style={{
                  backgroundColor: "gray",
                }}
                width={80}
                height={80}
                roundedCircle
              />
            </Col>
            <Col>
              <Row>
                <Name>{props.name}</Name>
              </Row>
              <Row>
                <Position>{props.position}</Position>
              </Row>
              <Row>
                <Descripton>{props.description}</Descripton>
              </Row>
            </Col>
          </Row>
          <Row>
            <Button height={50} color="black">
              Schedule
            </Button>
          </Row>
        </Container>
      </Center>
    </Body>
  );
}

export default MentorPreviewCard;

const Body = styled.div`
  background-color: white;
  border-radius: 20px;
  min-height: 100px;
  max-width: 400px;
  display: flex;
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 0px 10px -5px grey;
`;

const Name = styled.h6`
  font-family: Viga;
`;

const Descripton = styled.p`
  font-family: Viga;
  overflow-wrap: break-word;
  text-align: left;
`;

const Position = styled.p`
  font-family: Viga;
  font-style: italic;
`;
