import React from "react";
import styled from "styled-components";
import { FONTS } from "../../constants/fonts";

interface BubbleProps {
  text: string;
  color: string;
}

function Bubble(props: BubbleProps) {
  return <BubbleText color={props.color}>{props.text}</BubbleText>;
}

export default Bubble;

interface BubbleTextProps {
  color: string;
}

const BubbleText = styled.p<BubbleTextProps>`
  color: white;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: ${(props) => props.color};
  border-radius: 10px;
  font-family: ${FONTS.MONTSERRAT};
  font-weight: 500;
  font-size: 16px;
  margin: 0;
`;
