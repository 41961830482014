/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_appsync_graphqlEndpoint": "https://4umhajozt5bnzldpthhmiirnde.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-22apoqpsrzflpg7o3vkgtqnuce",
    "aws_cognito_identity_pool_id": "us-west-1:fd04e378-f8cc-41a4-b904-d3fa9a6df79e",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_rCUDyd6Ya",
    "aws_user_pools_web_client_id": "4k09knvu1i1o33ifn1sdjh15ep",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "BIRTHDATE",
        "NAME",
        "PICTURE",
        "GENDER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Users-staging",
            "region": "us-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "menti-website-storage-89fdb44242342-staging",
    "aws_user_files_s3_bucket_region": "us-west-1"
};


export default awsmobile;
