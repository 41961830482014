/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMentorUser = /* GraphQL */ `
  mutation CreateMentorUser(
    $input: CreateMentorUserInput!
    $condition: ModelMentorUserConditionInput
  ) {
    createMentorUser(input: $input, condition: $condition) {
      id
      name
      dob
      email
      avatar
      gender
      description
      company
      position
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMentorUser = /* GraphQL */ `
  mutation UpdateMentorUser(
    $input: UpdateMentorUserInput!
    $condition: ModelMentorUserConditionInput
  ) {
    updateMentorUser(input: $input, condition: $condition) {
      id
      name
      dob
      email
      avatar
      gender
      description
      company
      position
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMentorUser = /* GraphQL */ `
  mutation DeleteMentorUser(
    $input: DeleteMentorUserInput!
    $condition: ModelMentorUserConditionInput
  ) {
    deleteMentorUser(input: $input, condition: $condition) {
      id
      name
      dob
      email
      avatar
      gender
      description
      company
      position
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      message
      avatar
      inbox
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      message
      avatar
      inbox
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      message
      avatar
      inbox
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInbox = /* GraphQL */ `
  mutation CreateInbox(
    $input: CreateInboxInput!
    $condition: ModelInboxConditionInput
  ) {
    createInbox(input: $input, condition: $condition) {
      id
      lastMessage
      mentor
      mentee
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInbox = /* GraphQL */ `
  mutation UpdateInbox(
    $input: UpdateInboxInput!
    $condition: ModelInboxConditionInput
  ) {
    updateInbox(input: $input, condition: $condition) {
      id
      lastMessage
      mentor
      mentee
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInbox = /* GraphQL */ `
  mutation DeleteInbox(
    $input: DeleteInboxInput!
    $condition: ModelInboxConditionInput
  ) {
    deleteInbox(input: $input, condition: $condition) {
      id
      lastMessage
      mentor
      mentee
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMentiUser = /* GraphQL */ `
  mutation CreateMentiUser(
    $input: CreateMentiUserInput!
    $condition: ModelMentiUserConditionInput
  ) {
    createMentiUser(input: $input, condition: $condition) {
      id
      name
      dob
      email
      avatar
      description
      interest
      gender
      city
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMentiUser = /* GraphQL */ `
  mutation UpdateMentiUser(
    $input: UpdateMentiUserInput!
    $condition: ModelMentiUserConditionInput
  ) {
    updateMentiUser(input: $input, condition: $condition) {
      id
      name
      dob
      email
      avatar
      description
      interest
      gender
      city
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMentiUser = /* GraphQL */ `
  mutation DeleteMentiUser(
    $input: DeleteMentiUserInput!
    $condition: ModelMentiUserConditionInput
  ) {
    deleteMentiUser(input: $input, condition: $condition) {
      id
      name
      dob
      email
      avatar
      description
      interest
      gender
      city
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
