import styled from "styled-components";
import MessageCard from "../../components/Card/MessageCard";
import { FONTS } from "../../constants/fonts";

function MessaagesList() {
  return (
    <div className="hidden lg:flex lg:w-72 lg:flex-col mr-2">
      <Title>Messages</Title>
      <div
        style={{
          display: "flex",
          flex: 1,
          position: "relative",
        }}
      >
        <MessageCard />
      </div>
    </div>
  );
}

export default MessaagesList;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 400px;
  border-radius: 30px;
  background-color: white;
  border: 1px solid lightgrey;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 40px;
`;

const Title = styled.p`
  font-family: ${FONTS.VIGA};
  font-size: 25px;
  text-align: left;
`;

const ListContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: auto;
  flex-direction: column;
`;
