// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Gender = {
  "MALE": "MALE",
  "FEMALE": "FEMALE",
  "OTHER": "OTHER"
};

const { MentorUser, Message, Inbox, MentiUser, MentorUserMinified } = initSchema(schema);

export {
  MentorUser,
  Message,
  Inbox,
  MentiUser,
  Gender,
  MentorUserMinified
};