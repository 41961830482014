import { Search } from "react-bootstrap-icons";
import styled from "styled-components";
import { FONTS } from "../../constants/fonts";

function SearchBar() {
  return (
    <Container>
      <Search size={25} />
      <InputContainer>
        <Input placeholder="Search..." />
      </InputContainer>
    </Container>
  );
}

export default SearchBar;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  background-color: white;
  border-radius: 15px;
  border: lightgrey 1px solid;
  padding-left: 20px;
  align-items: center;
`;

const Input = styled.input`
  flex: 1;
  font-family: ${FONTS.MONTSERRAT};
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  outline: none;
  border: none;
  background-color: transparent;
`;

const InputContainer = styled.div`
  height: 50px;
  flex: 1;
  background-color: transparent;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  margin-right: 30px;
`;
