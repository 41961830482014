import { useEffect, useState } from "react";
import styled from "styled-components";
import BackButton from "../../components/Button/BackButton";
import MentorHeader from "../../components/Header/MentorHeader";
import MentorStats from "../Stats/MentorStats";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { GetMentorUserQuery } from "../../graphql/GraphQLApi";
import { getMentorUser } from "../../graphql/queries";
import { useParams } from "react-router-dom";
import { MentorUser } from "../../models";

function MentorDetails() {
  const { mentorId } = useParams();
  const [mentor, setMentor] = useState<MentorUser>();

  useEffect(() => {
    async function fetchMentor() {
      const mentor = await API.graphql<GraphQLQuery<GetMentorUserQuery>>({
        query: getMentorUser,
        variables: { id: mentorId },
      });

      setMentor(mentor.data?.getMentorUser!);
    }
    fetchMentor();
  }, []);

  return (
    <Container>
      <BackButton />
      <MentorHeader
        name={mentor?.name!}
        verified={false}
        company={mentor?.company!}
        position={mentor?.position!}
        avatar={mentor?.avatar!}
      />
      <div className=" max-w-3xl lg:mx-0 mt-6">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">
          About me
        </h2>
        <p className="mt-6 text-md leading-6 text-gray-600">
          Aliquip qui elit adipisicing excepteur duis id cupidatat exercitation
          dolor in elit. Voluptate elit reprehenderit reprehenderit amet esse
          sunt cupidatat laborum reprehenderit nulla ipsum consequat. Ut ad
          exercitation nulla sunt exercitation occaecat ex. Cupidatat minim sit
          aute occaecat aliquip dolore. Proident quis nisi officia quis nisi qui
          excepteur ut cupidatat sunt ullamco irure deserunt. Ipsum proident
          deserunt do qui deserunt est proident exercitation. Irure officia et
          reprehenderit quis duis sint sint eu amet cillum qui sint aliqua.
        </p>
      </div>
      <MentorStats />
    </Container>
  );
}

export default MentorDetails;

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fbf7f4;
`;
