import { Outlet } from "react-router-dom";

function MentorsOutlet() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default MentorsOutlet;
