import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { WHITE } from "../../constants/colors";
import { Center } from "../components";

interface AvailabilityProps {
  handleChange: (time: [number, number]) => void;
}

function Availability(props: AvailabilityProps) {
  const [time1, setTime1] = useState<string>("0");
  const [time2, setTime2] = useState<string>("23");

  const handleChange1 = (event: SelectChangeEvent) => {
    setTime1(event.target.value);
    // console.log(time1, time2);
    props.handleChange([parseInt(time1), parseInt(time2)]);
  };
  const handleChange2 = (event: SelectChangeEvent) => {
    setTime2(event.target.value);
    props.handleChange([parseInt(time1), parseInt(time2)]);
  };

  return (
    <AvailabilityContainer>
      <Container fluid>
        <Row>
          <Col>
            <Center>
              <SubTitle>Availability</SubTitle>
            </Center>
          </Col>
          <Col>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                From
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={time1}
                onChange={handleChange1}
                autoWidth
                label="Age"
              >
                <MenuItem value={47}>
                  <em>None</em>
                </MenuItem>
                {hours.map((time, index) => (
                  <MenuItem value={index.toString()}>{time}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                To
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={time2}
                onChange={handleChange2}
                autoWidth
                label="Age"
              >
                <MenuItem value={23}>
                  <em>None</em>
                </MenuItem>
                {hours.map((time, index) => (
                  <MenuItem value={index.toString()}>{time}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
        </Row>
      </Container>

      {/* <Slider defaultValue={30} step={10} marks min={10} max={110} disabled /> */}
    </AvailabilityContainer>
  );
}

export default Availability;

const AvailabilityContainer = styled.div`
  display: flex;
  border-radius: 15px;
  background-color: ${WHITE};
  padding: 20px;
`;

const SubTitle = styled.h4`
  font-family: Viga;
  display: flex;
  font-size: 30px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const hours = [
  "12:00 am",
  "1:00 am",
  "2:00 am",
  "3:00 am",
  "4:00 am",
  "5:00 am",
  "6:00 am",
  "7:00 am",
  "8:00 am",
  "9:00 am",
  "10:00 am",
  "11:00 am",
  "12:00 pm",
  "1:00 pm",
  "2:00 pm",
  "3:00 pm",
  "4:00 pm",
  "5:00 pm",
  "6:00 pm",
  "7:00 pm",
  "8:00 pm",
  "9:00 pm",
  "10:00 pm",
  "11:00 pm",
];
