const stats = [
  { name: "Subscribed", value: "5" },
  { name: "Up Coming Meetings", value: "4", unit: "" },
  { name: "Status", value: "Good" },
  { name: "Success rate", value: "98.5%" },
];

function MenteeStats() {
  return (
    <div className="shadow-md">
      <div className="mx-auto max-w-7xl">
        <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-4">
          {stats.map((stat) => (
            <div key={stat.name} className="px-4 py-6 sm:px-6 lg:px-8">
              <p className="text-sm font-medium leading-6 text-black">
                {stat.name}
              </p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-4xl font-semibold tracking-tight text-black">
                  {stat.value}
                </span>
                {stat.unit ? (
                  <span className="text-sm text-gray-500">{stat.unit}</span>
                ) : null}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MenteeStats;
