import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Authentication from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import Landing from "./pages/Landing";
import Schedule from "./pages/Schedule";
import SignUp from "./pages/SignUp";
import About from "./pages/About";
import Onboard from "./pages/Onboard/Onboard";
import Verification from "./pages/Verification";
import Messages from "./containers/Messages";
import DashboardHome from "./containers/DashboardHome";
import Calendar from "./containers/Calendar";
import Mentors from "./containers/Mentors/Mentors";
import MentorDetails from "./containers/Mentors/MentorDetails";
import MentorsOutlet from "./containers/Mentors/MentorsOutlet";
import NotFound from "./pages/Errors/NotFound";
import Dashboard2 from "./pages/Dashboard2";

function App() {
  useEffect(() => {
    // const firebaseConfig = {
    //   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    //   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    //   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    //   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    //   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    //   appId: process.env.REACT_APP_FIREBASE_APP_ID,
    //   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    // };
    // console.log(firebaseConfig);
  }, []);
  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/signin" element={<Authentication />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/onboard" element={<Onboard />} />
        <Route path="/dashboard" element={<Dashboard2 />}>
          <Route path="" element={<DashboardHome />} />
          <Route path="messages" element={<Messages />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="mentors" element={<MentorsOutlet />}>
            <Route path="" element={<Mentors />} />
            <Route path=":mentorId" element={<MentorDetails />} />
          </Route>
        </Route>
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/verification" element={<Verification />} />
      </Routes>
    </>
  );
}

export default App;
