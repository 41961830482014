import React from "react";
import styled from "styled-components";
import {
  HouseDoorFill,
  ChatFill,
  Calendar,
  People,
} from "react-bootstrap-icons";
import { FONTS } from "../../constants/fonts";
import { Link } from "react-router-dom";
import { classNames } from "../../util/styleUtil";

function SidebarItem(props: {
  label: string;
  current: boolean;
  icon: any;
  nav: string;
  onPress: () => void;
}) {
  return (
    <Link to={props.nav} style={{ color: "black" }} onClick={props.onPress}>
      <li
        key={props.label}
        className={classNames(
          props.current
            ? "bg-[#EEE6E2] text-black"
            : "text-gray-700 hover:text-gray-700 hover:bg-[#EEE6E2]",
          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
        )}
      >
        <props.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
        {props.label}
      </li>
    </Link>
  );
}

export default SidebarItem;

const Container = styled.button`
  height: 60px;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin-left: 30px;
  background-color: transparent;
  z-index: 2;
`;

const SideBarLabel = styled.span`
  font-size: 18px;
  margin-left: 10px;
  font-family: ${FONTS.MONTSERRAT};
  font-weight: 500;
`;
