import { Dispatch } from "redux";
import { MentiUser } from "../../types/types";
import { USER_SIGNUP } from "../types";

export const UserSignUp = (user: MentiUser) => {
  return (dispatch: Dispatch) => {
    dispatch(onSignUpSuccess(user));
  };
};

const onSignUpSuccess = (user: MentiUser) => {
  return {
    type: USER_SIGNUP,
    payload: { user: user, auth: { isSignedIn: true } },
  };
};
