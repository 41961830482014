import { Gender } from "../../models";
import { MentiUser, Action } from "../../types/types";
import { USER_SIGNIN, USER_SIGNUP } from "../types";

const defaultState: MentiUser = {
  id: "",
  name: "",
  dob: "",
  email: "",
  gender: Gender.OTHER,
  city: "",
  state: "",
};

const userReducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case USER_SIGNUP:
      return { ...state, ...action.payload.user };
    case USER_SIGNIN:
      return { ...state };
    default:
      return state;
  }
};

export default userReducer;
