import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, DataStore, API, Storage } from "aws-amplify";
import { useAppDispatch } from "../redux/hooks";
import { UserSignUp } from "../redux/actions/UserActions";
import InterestsJSON from "../db/interests.json";
import { MentiUser } from "../models";
import { Gender } from "../models";

const dateFormat = "YYYY/MM/DD";

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function Example() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [image, setImage] = useState<{ blob: Blob; uri: string } | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [avi, setAvi] = useState<string>("");
  const [avatar, setAvatar] = useState<File | undefined>(undefined);
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const inputFile = useRef<HTMLInputElement>(null);
  const history = useNavigate();
  const [user, setUser] = useState<MentiUser | undefined>(undefined);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const interestRef = useRef<HTMLSelectElement>(null);
  const dobRef = useRef<HTMLInputElement>(null);
  const genderRef = useRef<HTMLSelectElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  const removeImage = () => {
    setAvi(URL.createObjectURL(new Blob()));
    ref.current.value = "";
  };

  const onSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = e.target.files && e.target.files[0];
    if (!fileObj) {
      return;
    }
    const objectUrl = URL.createObjectURL(fileObj);
    setImage({ blob: fileObj, uri: objectUrl });
  };

  const onSignUp = async () => {
    console.log("Sign up");
    setLoading(false);

    // Auth.signUp({
    //   username: "amohamud23@gmail.com",
    //   password: "Password123.",
    //   attributes: {
    //     email: "amohamud23@gmail.com",
    //     picture: "image",
    //     gender: "M",
    //     birthdate: "01/01/2022",
    //     name: "John Doe",
    //   },
    // }).then((res) => {
    //   dispatch(UserSignUp(user));
    //   history("/verification");
    //   console.log(res.user);
    // });

    await Auth.signUp({
      username: emailRef.current?.value!,
      password: passwordRef.current?.value!,
      attributes: {
        email: emailRef.current?.value,
        picture: "",
        gender: "Male",
        birthdate: "01/01/2023",
        name: "Abdi Mohamud",
      },
    });
    await Auth.signIn({
      username: "test234@menti.com",
      password: "Password123.",
    });

    const storageKey = await Storage.put(
      `user/${emailRef.current?.value}/avatar/avatar.png`,
      image?.blob,
      {
        contentType: "image/png", // contentType is optional
      }
    );
    const signStorageUrl = await Storage.get(storageKey.key, {
      level: "public",
    });
    const menti = new MentiUser({
      name: firstNameRef.current?.value + " " + lastNameRef.current?.value,
      email: "amohamud23@gmail.com",
      dob: new Date(dobRef.current?.value!).toISOString(),
      avatar: signStorageUrl,
      description: "",
      interest: interestRef.current?.value,
      city: "",
      state: "",
      gender: genderRef.current?.value! == "male" ? Gender.MALE : Gender.FEMALE,
    });

    const doc = await DataStore.save(menti);
    console.log(doc);
    dispatch(UserSignUp(doc));
    navigate("/dashboard");
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 ">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Create a Menti Account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[780px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <div className="divide-y divide-white/5">
              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-black">
                    Personal Information
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    Use a permanent address where you can receive mail.
                  </p>
                </div>

                <div className="md:col-span-2">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="col-span-full flex items-center gap-x-8">
                      <img
                        src={image?.uri}
                        alt=""
                        className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                      />
                      <div>
                        <input
                          type="file"
                          id="file"
                          ref={inputFile}
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={onSelectImage}
                        />
                        <button
                          type="button"
                          className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-white/20"
                          onClick={() => inputFile.current?.click()}
                        >
                          Change avatar
                        </button>
                        <p className="mt-2 text-xs leading-5 text-gray-400">
                          JPG, GIF or PNG. 1MB max.
                        </p>
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        First name
                      </label>
                      <div className="mt-2">
                        <input
                          ref={firstNameRef}
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        Last name
                      </label>
                      <div className="mt-2">
                        <input
                          ref={lastNameRef}
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          ref={emailRef}
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="col-span-full">
                      <label
                        htmlFor="timezone"
                        className="block text-sm font-medium leading-6 text-blaack"
                      >
                        Interests
                      </label>
                      <div className="mt-2">
                        <select
                          ref={interestRef}
                          id="timezone"
                          name="timezone"
                          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                        >
                          {InterestsJSON.map((interest) => (
                            <option key={interest.valie}>
                              {interest.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        Date of Birth
                      </label>
                      <div className="mt-2">
                        <input
                          ref={dobRef}
                          id="dob"
                          name="dob"
                          type="date"
                          className=" px-2 block w-full rounded-md border-0 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="timezone"
                        className="block text-sm font-medium leading-6 text-blaack"
                      >
                        Gender
                      </label>
                      <div className="mt-2">
                        <select
                          ref={genderRef}
                          id="timezone"
                          name="timezone"
                          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                        >
                          <option key="male">Male</option>
                          <option key="female">Female</option>
                          <option key="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-black">
                    Password
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    Update your password associated with your account.
                  </p>
                </div>

                <div className="md:col-span-2">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="col-span-full">
                      <label
                        htmlFor="new-password"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        Password
                      </label>
                      <div className="mt-2">
                        <input
                          ref={passwordRef}
                          id="new-password"
                          name="new_password"
                          type="password"
                          autoComplete="new-password"
                          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="confirm-password"
                        className="block text-sm font-medium leading-6 text-black"
                      >
                        Confirm password
                      </label>
                      <div className="mt-2">
                        <input
                          ref={confirmPasswordRef}
                          id="confirm-password"
                          name="confirm_password"
                          type="password"
                          autoComplete="new-password"
                          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 flex">
                    <button
                      onClick={onSignUp}
                      className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
