import React from "react";
import styled from "styled-components";
import { FONTS } from "../constants/fonts";
import UpcomingMeetings from "./Meetings/UpcomingMeetings";
import MenteeStats from "./Stats/MenteeStatus";
import { useAppSelector } from "../redux/hooks";

function DashboardHome() {
  const user = useAppSelector((state) => state.UserReducer);
  return (
    <Container>
      <Title>Welcome</Title>
      <Title>{user.name}!</Title>
      <MenteeStats />
      <UpcomingMeetings />
    </Container>
  );
}

export default DashboardHome;

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: ${FONTS.VIGA};
  font-size: 40px;
  text-align: left;
  margin: 0;
`;
