// import React, { useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import styled from "styled-components";
import { BLUE } from "../constants/colors";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { MentiUser } from "../types/types";

function VerificationCode() {
  // const [code, setCode] = useState<string>("");
  const user = useAppSelector((state) => state.UserReducer);
  const history = useNavigate();
  const handleChange = (val: string) => {
    // setCode(val);
    if (val.length >= 6) {
      console.log(val.length, val);
      Auth.confirmSignUp(user.email, val).then(() => {
        history("/dashboard");
      });
    } else if (val.length === 0) {
      console.log("empty, ", val);
    }
  };

  const resendCode = () => {
    Auth.resendSignUp(user.email);
  };

  return (
    <Body>
      <Title>Verify</Title>
      <SubTitle onClick={resendCode}>Resend Code</SubTitle>
      <ReactCodeInput
        fields={6}
        onChange={handleChange}
        onComplete={(value) => console.log("COMPLETE ", value)}
      />
    </Body>
  );
}

export default VerificationCode;

const Body = styled.div`
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 0px 30px -15px grey;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 600px;
  padding: 70px;
  align-self: center;
  align-items: center;
  width: 95%;
  max-width: 1000px;
  margin-top: 80px;
  margin-bottom: 80px;
`;

const Title = styled.h1`
  font-family: Viga;
  margin-bottom: 50px;
`;

const SubTitle = styled.a`
  font-family: Viga;
  color: ${BLUE};
  margin-bottom: 120px;
`;
